<style lang="less">
.video_box {
    min-width: 0 !important;
    width: 128px !important;
    height: 178px !important;
    box-sizing: border-box;
}
.user_model {
    .el-dialog__body {
        padding: 10px;
    }
    .el-dialog__header {
        display: none;
    }
}
</style>
<template>
    <div>
        <el-drawer
            :with-header="false"
            size="1200px"
            :append-to-body="true"
            :before-close="
                () => {
                    impoShow = false;
                }
            "
            :visible.sync="impoShow"
        >
            <div class="impo-content">
                <div class="source-box">
                    <SOURCE_MATERIAL
                        ref="daoru"
                        :impoType="3"
                        :material="'mev'"
                        :sourec_type="1"
                        :key="Math.random()"
                    />
                </div>

                <div slot="footer" class="source-btn-box">
                    <el-button @click="impoShow = false" size="mini"
                        >取 消</el-button
                    >
                    <el-button type="primary" @click="daoru" size="mini"
                        >确 定</el-button
                    >
                </div>
            </div>
        </el-drawer>
        <div class="content">
            <div class="title">
                <div @click="$goBack">
                    <i class="el-icon-arrow-left"></i>
                    发布视频
                </div>
            </div>
            <div class="video-box">
                <div class="video-hd">
                    <div class="up-box">
                        <!-- <el-upload
                            action="/api/app/Videocreationfile/multiple"
                            :data="{
                                // projectType: 'Material',
                                projectType: 'mev',
                                directoryId: '',
                            }"
                            :headers="{ Authorization: token }"
                            name="files"
                            multiple
                            accept="video/*"
                            :on-success="onSuccess"
                            :on-progress="onProgress"
                            :show-file-list="false"
                            :before-upload="beforeAvatarUpload"
                        >
                            <el-button type="primary" size="mini"
                                >+添加视频</el-button
                            >
                        </el-upload> -->
                        <el-button
                            type="primary"
                            size="mini"
                            style="margin-left: 10px"
                            @click="impoShow = true"
                            >+导入视频</el-button
                        >
                        <div class="video-num">
                            <span>视频数量：</span
                            >{{ upVideo.length + videos.length }}/500
                        </div>
                    </div>
                    <div class="up-tis">
                        <div>
                            视频时长≤15分钟，大小≤4GB,推荐上传720p以上分辨率，最高支持8K；支持常见视频格式，推荐使用MP4
                        </div>
                        <div>
                            注：因渠道平台要求，请勿发布违规，搬运及低质等内容，否则平台将有权进行包含但不限于限制发布能力，封号等处理
                        </div>
                    </div>
                </div>
                <div class="video-conten">
                    <!-- 上传列表 -->
                    <div
                        class="video-gruop"
                        v-for="(item, index) in upVideo"
                        :key="index"
                    >
                        <div
                            class="video-item"
                            v-loading="item.percentage == 100"
                            element-loading-text="正在保存"
                        >
                            <div class="video-play">
                                <div
                                    class="video-img"
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    "
                                >
                                    <el-progress
                                        v-if="item.percentage != 100"
                                        type="circle"
                                        :width="80"
                                        :format="format"
                                        :percentage="item.percentage"
                                        :stroke-width="4"
                                    ></el-progress>
                                </div>
                                <div class="video-text">视频文件</div>
                            </div>
                            <div class="video-img">
                                <div class="video-img"></div>
                                <div class="video-text">视频封面</div>
                            </div>
                        </div>
                    </div>
                    <!-- 发布列表 -->
                    <div
                        class="video-gruop"
                        v-for="(item, index) in videos"
                        :key="item.id"
                    >
                        <span
                            class="close-btn"
                            title="点击删除视频"
                            @click="remove_video(index)"
                        >
                            <i class="el-icon-close"></i>
                        </span>
                        <div class="video-item">
                            <!-- <div class="video-play"> -->
                            <div class="video-img">
                                <a href="javascript:;">
                                    <el-popover
                                        placement="right"
                                        popper-class="video_box"
                                        trigger="hover"
                                    >
                                        <video
                                            :src="
                                                $store.state.ossVideoUrl +
                                                item.fileUrl
                                            "
                                            width="100%"
                                            height="100%"
                                            muted
                                            autoplay
                                        ></video>
                                        <el-button slot="reference">
                                            <img
                                                src="@/assets/images/play.png"
                                                width="50px"
                                                alt=""
                                            />
                                        </el-button>
                                    </el-popover>
                                </a>
                                <el-image
                                    style="width: 100%; height: 100%"
                                    :src="item.coverUrl"
                                    fit="cover"
                                ></el-image>
                                <div class="video-text">视频文件</div>
                            </div>
                            <!-- </div> -->
                            <div class="video-img">
                                <a href="javascript:;">
                                    <!-- <el-button
                                        type="primary"
                                        size="small"
                                        @click="$message.error('暂未开放')"
                                        >更换封面</el-button
                                    > -->
                                    <el-upload
                                        action="/api/app/Videocreationfile/update"
                                        name="coverUrl"
                                        :data="{ id: item.id, index: index }"
                                        accept="image/*"
                                        :multiple="false"
                                        :before-upload="beforeUpload"
                                        :show-file-list="false"
                                        :http-request="upload"
                                    >
                                        <el-button type="primary" size="small"
                                            >更换封面</el-button
                                        >
                                    </el-upload>
                                </a>
                                <div class="video-img">
                                    <el-image
                                        style="width: 100%; height: 100%"
                                        :src="item.coverUrl"
                                        fit="cover"
                                    ></el-image>
                                </div>
                                <div class="video-text">视频封面</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="up-content">
                <div class="up-card">
                    <div class="up-title">投放渠道</div>
                    <div class="up-body">
                        <div class="mode-box">
                            <div class="mode-item">
                                <ul
                                    class="mode-select"
                                    :class="upmode == 'douyin' ? 'active' : ''"
                                    @click="modeTab('douyin')"
                                >
                                    <li class="mode-icon">
                                        <img
                                            src="@/assets/images/work/dou.png"
                                            alt=""
                                        />
                                    </li>
                                    <li class="mode-tis">
                                        <div>抖音平台</div>
                                        <div>抖音矩阵多元组合</div>
                                    </li>
                                </ul>
                                <ul
                                    class="mode-select"
                                    :class="
                                        upmode == 'kuaishou' ? 'active' : ''
                                    "
                                    @click="modeTab('kuaishou')"
                                >
                                    <li class="mode-icon">
                                        <img
                                            src="@/assets/images/work/kuai.png"
                                            alt=""
                                        />
                                    </li>
                                    <li class="mode-tis">
                                        <div>快手平台</div>
                                        <div>快手矩阵多元组合</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="up-edit-content">
                    <div class="dist-mode">
                        <div class="up-card">
                            <div class="up-title">分发模式</div>
                            <div class="up-body">
                                <el-radio-group v-model="upData.releaseType">
                                    <el-radio
                                        border
                                        size="mini"
                                        :label="1"
                                        disabled
                                        >派发任务</el-radio
                                    >
                                    <el-radio border size="mini" :label="2"
                                        >平台自动分发</el-radio
                                    >
                                    <el-radio
                                        border
                                        size="mini"
                                        :label="3"
                                        :disabled="upmode != 'douyin'"
                                        >随机派发</el-radio
                                    >
                                </el-radio-group>
                                <ul class="tis-box">
                                    <li v-if="upData.releaseType == 1">
                                        通过短信自动派发任务至抖音号所关联的手机号码上，通过移动端进行手动发布至抖音。同时生成发布链接，可使用手机浏览器打开链接进行发布
                                    </li>
                                    <li v-else-if="upData.releaseType == 3">
                                        使用移动端或抖音APP扫码随机分配视频进行手动发布
                                    </li>
                                    <li v-else>
                                        通过火键平台自动分发抖音，无需手动操作，即和原来发布流程一致
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="up-edit-title">
                        <!-- 标题 -->
                        <div class="up-edit-header">
                            <!-- <el-button type="primary" size="mini"
                                >+ 添加标题</el-button
                            > -->
                            <!-- <el-button size="mini" @click="tabTitle(2)">智能化标题</el-button> -->
                            <div class="title_tab">
                                <ul class="title_tab_group">
                                    <li
                                        class="title_tab_list"
                                        :class="
                                            title_active == 1 ? 'active' : 0
                                        "
                                        @click="title_active = 1"
                                    >
                                        自定义标题
                                    </li>
                                    <li
                                        class="title_tab_list"
                                        :class="
                                            title_active == 2 ? 'active' : 0
                                        "
                                        @click="title_active = 2"
                                    >
                                        智能化标题
                                    </li>
                                </ul>
                            </div>
                            <div v-if="title_active == 2">
                                <el-autocomplete
                                    v-model="keyword"
                                    :fetch-suggestions="searchKey"
                                    placeholder="请输入关键字标题"
                                    @select="changeKey"
                                ></el-autocomplete>
                            </div>
                            <!-- <div class="title-num" v-if="title_active == 1">
                                <span>已选：</span>
                                200/00
                            </div> -->
                            <div class="title-tis" v-if="title_active == 1">
                                标题数量需要与视频数量保持一致，标题需必填且不少于5个字，视频将与标题随机组合
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="$refs.tag_edit.addTitle()"
                                    >添加标题</el-button
                                >
                            </div>
                        </div>
                        <div class="up-edit-box" v-if="title_active == 1">
                            <div class="up-edit-list">
                                <div class="edit-box">
                                    <tag_textarea
                                        ref="tag_edit"
                                        :releaseType="upData.releaseType"
                                        :upmode="upmode"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="up-relation">
                        <div
                            class="relation-row relation-group"
                            style="display: none"
                        >
                            <!-- 视频分类 -->
                            <div class="relation-left">
                                <div class="up-card">
                                    <div class="up-title">视频分类</div>
                                    <div class="up-body">
                                        <el-input
                                            placeholder="请选择视频分类"
                                            v-model="upData.goodUrl"
                                            size="small"
                                            :disabled="true"
                                        >
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="relation-row relation-group"
                            v-if="upData.releaseType != 3 && upmode == 'douyin'"
                        >
                            <!-- 关联商品 -->
                            <div class="relation-left">
                                <div class="up-card">
                                    <div class="up-title">是否关联商品</div>
                                    <div class="up-body">
                                        <el-radio-group
                                            v-model="relationGoods"
                                            size="mini"
                                            @input="setMount('relationGoods')"
                                        >
                                            <el-radio :label="0" border
                                                >不关联</el-radio
                                            >
                                            <el-radio :label="1" border
                                                >关联</el-radio
                                            >
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                            <!-- 商品链接 -->
                            <div class="relation-right" style="display: flex">
                                <div class="up-card">
                                    <div class="up-title">商品短标题</div>
                                    <div class="up-body">
                                        <el-input
                                            placeholder="请输入商品标题"
                                            v-model="upData.goodShotTitle"
                                            size="mini"
                                            :disabled="relationGoods != 1"
                                        >
                                        </el-input>
                                    </div>
                                </div>
                                <div class="up-card" style="margin-left: 10px">
                                    <div class="up-title">商品链接</div>
                                    <div class="up-body">
                                        <el-input
                                            placeholder="请输入商品链接"
                                            v-model="upData.goodUrl"
                                            size="mini"
                                            :disabled="relationGoods != 1"
                                            class="input-w400"
                                        >
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="relation-row relation-group"
                            v-if="upData.releaseType != 3 && upmode == 'douyin'"
                        >
                            <!-- 关联小程序 -->
                            <div class="relation-left">
                                <div class="up-card">
                                    <div class="up-title">是否关联小程序</div>
                                    <div class="up-body">
                                        <el-radio-group
                                            v-model="relationApp"
                                            size="mini"
                                            @input="setMount('relationApp')"
                                        >
                                            <el-radio :label="0" border
                                                >不关联</el-radio
                                            >
                                            <el-radio :label="1" border
                                                >关联</el-radio
                                            >
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                            <!-- 关联链接 -->
                            <div class="relation-right">
                                <div class="up-card">
                                    <div class="up-title">小程序链接</div>
                                    <div class="up-body">
                                        <el-input
                                            class="input-w400"
                                            placeholder="请输入小程序链接"
                                            v-model="apptUrl"
                                            size="mini"
                                            :disabled="relationApp != 1"
                                        >
                                        </el-input>
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            style="margin-left: 8px"
                                            @click="getApps"
                                            >确认</el-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="relation-row relation-group"
                            v-if="upmode == 'douyin'"
                        >
                            <!-- 关联位置 -->
                            <div class="relation-left">
                                <div class="up-card">
                                    <div class="up-title">是否关联地点</div>
                                    <div class="up-body">
                                        <el-radio-group
                                            @input="setMount('relationAdd')"
                                            @change="add_change"
                                            v-model="relationAdd"
                                            size="mini"
                                        >
                                            <el-radio :label="0" border
                                                >不关联</el-radio
                                            >
                                            <el-radio :label="1" border
                                                >关联地址</el-radio
                                            >
                                            <el-radio
                                                :label="2"
                                                border
                                                v-if="upData.releaseType != 3"
                                                >关联用户地址</el-radio
                                            >
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                            <!-- 选择poi位置 -->
                            <div class="relation-right">
                                <div class="up-card">
                                    <div class="up-title">选择位置</div>
                                    <div class="up-body">
                                        <el-autocomplete
                                            class="inline-input input-w400"
                                            size="mini"
                                            v-model="add"
                                            :disabled="relationAdd != 1"
                                            :fetch-suggestions="remoteMethod"
                                            placeholder="请输入位置关键字"
                                            :trigger-on-focus="false"
                                            @select="selected"
                                        >
                                            <template slot-scope="{ item }">
                                                <div
                                                    style="
                                                        padding: 6px 0;
                                                        line-height: 1.3;
                                                    "
                                                >
                                                    <div class="name">
                                                        {{ item.poi_name }}
                                                    </div>
                                                    <span
                                                        style="
                                                            font-size: 12px;
                                                            color: #a7a7a7;
                                                        "
                                                        class="addr"
                                                        >{{
                                                            item.address
                                                        }}</span
                                                    >
                                                </div>
                                            </template>
                                        </el-autocomplete>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 添加位置 -->
                        <!-- <div class="relation-row relation-group">
                            <div class="relation-left">
                                <div class="up-card">
                                    <div class="up-title">
                                        <el-button type="primary" size="mini"
                                            >+添加位置</el-button
                                        >
                                        <el-button size="mini"
                                            >历史位置</el-button
                                        >
                                    </div>
                                    <div class="up-body">
                                        <el-autocomplete
                                            class="inline-input"
                                            size="mini"
                                            v-model="add"
                                            :fetch-suggestions="remoteMethod"
                                            placeholder="请输入位置关键字"
                                            :trigger-on-focus="false"
                                            @select="selected"
                                        ></el-autocomplete>
                                    </div>
                                </div>
                            </div>
                            <div class="relation-right">
                                <div class="up-card">
                                    <div class="up-title title-tis">
                                        位置优先跟随账号本身携带的位置，添加位置后，未添加位置的视频与添加的位置做随机组合
                                    </div>
                                    <div class="up-body"></div>
                                </div>
                            </div>
                        </div> -->
                        <!-- 添加账号 -->
                        <div
                            class="relation-row relation-group"
                            v-if="upData.releaseType != 3"
                        >
                            <div class="relation-left">
                                <div class="up-card">
                                    <div class="up-title" style="display: flex">
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            @click="userSelect = true"
                                            >+添加账号</el-button
                                        >
                                        <div class="title-num">
                                            <span>发布账号个数：</span>
                                            {{ send.users.length }}/200
                                        </div>
                                    </div>
                                    <div class="up-body">
                                        <div class="user-select-box">
                                            <el-tag
                                                type="info"
                                                closable
                                                v-for="(
                                                    item, index
                                                ) in send.users"
                                                :key="index"
                                                >{{ item.userNickname }}</el-tag
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="relation-right">
                                <div class="up-card">
                                    <div class="up-title title-tis">
                                        选择的发布账号数不能大于视频数
                                    </div>
                                    <div class="up-body"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="release-set" v-if="upData.releaseType == 2">
                    <!-- 发布设置 -->
                    <div class="release-set-row">
                        <div class="up-card">
                            <div class="up-title">发布设置</div>
                            <div class="up-body">
                                <el-radio-group
                                    v-model="upData.releaseSetup"
                                    size="mini"
                                >
                                    <el-radio :label="1" border
                                        >一个账号对应一个视频</el-radio
                                    >
                                    <el-radio :label="2" border
                                        >一个账号对应多个视频</el-radio
                                    >
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                    <!-- 发布时间 -->
                    <div class="release-set-row">
                        <div class="up-card">
                            <div class="up-title">发布时间</div>
                            <div class="up-body">
                                <div class="set-time">
                                    <div
                                        class="set-time-item"
                                        style="flex: 0 0 140px"
                                    >
                                        <el-radio
                                            v-model="upData.isScheduled"
                                            :label="0"
                                            border
                                            size="mini"
                                            @change="publish_now"
                                            >立即发布</el-radio
                                        >
                                    </div>
                                    <div class="set-time-item">
                                        <el-radio
                                            v-model="upData.isScheduled"
                                            :label="1"
                                            border
                                            size="mini"
                                            >定时发布</el-radio
                                        >
                                        <div v-if="upData.isScheduled == 1">
                                            <el-date-picker
                                                v-model="upData.releaseTime"
                                                type="datetime"
                                                :picker-options="forbiddenTime"
                                                placeholder="选择日期时间"
                                                :default-value="new Date()"
                                                size="mini"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                            >
                                            </el-date-picker>
                                            <!-- value-format="yyyy-MM-dd HH:mm:ss" -->
                                        </div>

                                        <div
                                            class="time-set-box"
                                            v-if="upData.isScheduled == 1"
                                        >
                                            <el-select
                                                v-model="upData.releaseInterval"
                                                placeholder="请选择"
                                                size="mini"
                                            >
                                                <el-option
                                                    :label="k.txt"
                                                    :value="k.num"
                                                    v-for="(
                                                        k, i
                                                    ) in intervalList"
                                                    :key="i"
                                                >
                                                </el-option>
                                            </el-select>
                                            <el-radio-group
                                                v-model="upData.intervalType"
                                            >
                                                <div class="radio-item">
                                                    <el-radio
                                                        :label="1"
                                                        size="mini"
                                                        >单账号发布视频做间隔</el-radio
                                                    >
                                                </div>
                                                <div class="radio-item">
                                                    <el-radio :label="2"
                                                        >多账号发布视频做间隔</el-radio
                                                    >
                                                </div>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="release-set" v-else>
                    <div class="release-set-row">
                        <div class="up-card">
                            <div class="up-title">视频循环设置</div>
                            <div
                                class="up-body"
                                style="display: flex; align-items: center"
                            >
                                <el-radio-group
                                    v-model="upData.repeatRelease"
                                    size="mini"
                                >
                                    <el-radio :label="0" border
                                        >不开启视频循环发布</el-radio
                                    >
                                    <el-radio :label="1" border
                                        >开启视频循环发布</el-radio
                                    >
                                </el-radio-group>
                                <div
                                    style="margin-left: 40px; color: #f00"
                                    v-show="upData.circulate"
                                >
                                    开启循环播放可能判定重复,影响曝光量
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-box">
                    <el-button type="primary" size="small" @click="onsubmit"
                        >提交</el-button
                    >
                </div>
            </div>
        </div>
        <!-- 账号选择弹窗 -->
        <el-dialog
            title="账号选择"
            :visible.sync="userSelect"
            width="622px"
            :show-close="false"
            custom-class="user_model"
        >
            <div class="select_user-box">
                <SELECT_USER ref="userGruop" :mode="upmode" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="userSelect = false" size="small"
                    >取 消</el-button
                >
                <el-button type="primary" @click="getUser" size="small"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 选择封面 -->
        <!-- <el-dialog
            title="选择封面"
            :visible.sync="isChangeCover"
            width="800px"
            :show-close="false"
            destroy-on-close
        >
            <div class="select_user-box">
                <el-upload
                    class="upload_cover"
                    action="/api/app/Videocreationfile/multiple"
                    drag
                    accept="image/*"
                    :multiple="false"
                    :on-change="beforeUpload"
                    :show-file-list="false"
                    :auto-upload="false"
                    :http-request="upload"
                    :limit="1"
                >
                </el-upload>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isChangeCover = false" size="small"
                    >取 消</el-button
                >
                <el-button type="primary" @click="changeCover" size="small"
                    >确 定</el-button
                >
            </span>
        </el-dialog> -->
        <el-dialog
            title="扫二维码发布视频"
            :visible.sync="showQR"
            :before-close="closeQR"
            custom-class="qr_box"
            width="560px"
        >
            <QR :url="qr_url" />
        </el-dialog>
        <el-dialog
            title="发布记录"
            :visible.sync="showReleaseRecord"
            width="30%"
        >
            <div>
                <el-table :data="releaseRecord" style="width: 100%" size="mini">
                    <el-table-column
                        prop="accountName"
                        label="用户"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="releaseState"
                        label="状态"
                        width="180"
                    >
                        <template slot-scope="scope">
                            <el-tag
                                v-if="scope.row.releaseState == 1"
                                type="success"
                                disable-transitions
                                size="mini"
                                >成功</el-tag
                            >
                            <el-tag
                                v-else
                                type="danger"
                                size="mini"
                                disable-transitions
                                >失败<span v-if="scope.row.errorMsg == 'sms'"
                                    >，用户需要手机验证</span
                                ></el-tag
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showReleaseRecord = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { publish, searchPoi, searchApp, publishKs } from "@/api/publish";

import OSS from "ali-oss";
import { upfliem, getSignOss } from "@/api/upload";
import { videoUpdata } from "@/api/api";
import SELECT_USER from "@/components/selectUser";

import SOURCE_MATERIAL from "@/components/work/sourceMaterial";
import * as moment from "moment";
import tag_textarea from "@/components/release/tag_textarea";
// import title_edits from "@/components/release/title_edits";
import QR from "@/components/h5/qr";

import { getKeyword } from "@/api/keyword";
export default {
    name: "up",
    components: {
        // IMPORT_DIALOG,
        SELECT_USER,
        SOURCE_MATERIAL,
        QR,
        tag_textarea,
    },
    created() {},
    data() {
        return {
            filelist: [],
            title_active: 1,
            video_url: "",
            forbiddenTime: {
                //禁用当前日期之前的日期
                disabledDate(time) {
                    //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            impoShow: false,
            loading: false,
            adds: [],
            add: "",
            uptimer: "",
            apptUrl: "", //小程序链接
            cate: [
                { id: 1, msg: "美食" },
                { id: 2, msg: "风景" },
                { id: 3, msg: "搞笑" },
                { id: 4, msg: "视频" },
            ],
            intervalList: [
                {
                    num: "",
                    txt: "无间隔",
                },
                {
                    num: 10,
                    txt: "10分钟",
                },
                {
                    num: 20,
                    txt: "20分钟",
                },
                {
                    num: 30,
                    txt: "30分钟",
                },
            ],
            upmode: "douyin",
            //是否关联商品
            relationGoods: 0,
            //是否关联小程序
            relationApp: 0,
            //是否关联位置
            relationAdd: 0,
            // 选择账号弹窗
            userSelect: false,
            upData: {
                mode: 1, //模式，1抖音2快手
                releaseType: 2, //1.派发任务 2.平台派发 3.随机派发
                authorizedUserList: [], //用户列表
                videoList: [], //视频列表
                intervalType: "", //1.单个账号发布视频做间隔 2.多账号发布视频做间隔
                isScheduled: 0, //是否为定时发布 0不是 1是
                releaseInterval: "", //分钟
                releaseSetup: 1, //1,一对一，2，一对多
                title: "", //视频标题
                poiId: "", //poi地址ID
                poiName: "",
                relationGoods: 0, //是否关联商品
                goodShotTitle: "", //商品标题
                goodUrl: "", //商品链接
                anchor: "",
                releaseTime: "",
                repeatRelease: 0,
            },
            //   视频列表
            videos: [],

            // 旧
            value: "",
            showUp: false,
            releaseInterval: "",
            groupId: "",
            showQR: false,
            qr_url: "",
            showImpo: false,
            // 导入弹窗控制
            show: false,
            //标题
            textarea: "",
            changeItem: 0, //平台类型1.抖音 ，2.快手
            //上传列表
            upVideo: [],
            timer: {
                starTime: "",
                endTime: "",
            },
            // 发布参数
            send: {
                videoList: [], //视频列表
                users: [], //已选的用户呢称
                title: "testtesttesttest", //视频标题
                releaseType: "", //分发模式 1.派发任务 2.平台派发 3.随机派发
            },

            token: this.$store.state.token,
            //   --------
            uplist: [],
            releaseRecord: [],
            showReleaseRecord: false,
            keyword: "",
            keyList: [],
            selectKey: "",
            isChangeCover: true,
        };
    },
    methods: {
        add_change(val) {
            // console.log(val);
            if (val == 2) {
                // 关联授权用户地址
                this.upData.bindUserAddress = true;
                this.upData.poiId = "";
                this.upData.poiName = "";
            } else {
                this.upData.bindUserAddress = false;
            }
        },
        addTitle() {
            this.$refs.tag_edit.addTitle();
        },
        showVideo(url) {
            this.video_url = this.$store.state.video + url;
        },
        publish_now() {
            this.upData.intervalType = "";
        },
        modeTab(i) {
            this.upmode = i;
        },
        remove_video(index) {
            this.videos.splice(index, 1);
        },
        async searchKey(queryString, cb) {
            let json = {
                current: 1,
                size: 30,
                configName: this.keyword,
            };
            let res = await getKeyword(json);
            let keyList = res.data.records;
            keyList.forEach((item) => {
                item.value = item.configName;
            });
            cb(keyList);
        },
        async changeKey(item) {
            this.selectKey = item;
        },
        // 视频挂载三选一
        setMount(item) {
            this[item];
            if (this[item] == 1) {
                switch (item) {
                    case "relationGoods":
                        this.relationApp = this.relationAdd = 0;
                        break;

                    case "relationApp":
                        this.relationGoods = this.relationAdd = 0;
                        break;

                    case "relationAdd":
                        this.relationGoods = this.relationApp = 0;
                        break;

                    default:
                        break;
                }
            }
        },
        impoClose() {
            // console.log(1);
        },
        selected(item) {
            this.upData.poiId = item.poi_id;
            this.upData.poiName = item.poi_name;
        },
        remoteMethod(query, cb) {
            if (query != "") {
                let json = {
                    keyword: query,
                    cursor: 1,
                };
                searchPoi(json).then((res) => {
                    this.adds = res.data.pois;
                    res.data.pois.forEach((k, i) => {
                        k.value = k.poi_name;
                    });
                    cb(res.data.pois);
                });
            }
        },
        getApps() {
            let apps_link = this.apptUrl;
            let check_link = this.isUrl(apps_link);
            if (!check_link) {
                this.$alert("非法链接");
                return;
            }
            let json = { keyword: apps_link };

            searchApp(json).then((res) => {
                try {
                    let resData = JSON.parse(res.msg);
                    // 提示
                    let html_str = "";
                    if (resData.status_code == 0) {
                        // 小程序名字
                        let apps_name = resData.anchor_list[0].name;
                        // 小程序描述
                        let apps_summary = resData.anchor_list[0].summary;
                        // 查询到小程序
                        html_str = `<div>
                        <h3>小程序信息</h3>
                        <p>小程序：${apps_name}</p>
                        <p> 描 述：${apps_summary}</p>
                        <div>`;
                        this.$confirm(html_str, "是否关联此小程序", {
                            confirmButtonText: "关联",
                            cancelButtonText: "取消",
                            type: "warning",
                            dangerouslyUseHTMLString: true,
                        }).then(() => {
                            // 关联小程序成功
                            this.upData.anchor = JSON.stringify(
                                resData.anchor_list[0]
                            );
                            // console.log();
                            this.$message.success(`成功关联${apps_name}`);
                        });
                    } else {
                        this.$alert("查询不到小程序");
                    }
                } catch (error) {
                    this.$alert("链接有误");
                }
            });
        },
        isUrl(str) {
            var v = new RegExp(
                "^(?!mailto:)(?:(?:http|https)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$",
                "i"
            );
            return v.test(str);
        },

        setStarTime() {
            let startTime = moment()
                .add(3, "hours")
                .format("YYYY-MM-DD HH:mm:ss");
            this.timer.starTime = startTime;
        },
        daoru() {
            //导入视频
            let selectVideo = this.$refs.daoru.select_data;
            // console.log(selectVideo);
            this.videos = [...this.videos, ...selectVideo];
            this.impoShow = false;
        },
        async onsubmit() {
            //   判断标题是否选择
            let upData = this.upData;

            let users = [];
            this.send.users.map((item) => {
                users.push(item.id);
            });

            upData.authorizedUserList = JSON.stringify(users);

            //   判断是否选择了视频文件
            if (this.videos.length == "") {
                this.$message.error("请选择发布的视频文件");
                return;
            }
            let videoList = [];
            this.videos.map((item) => {
                videoList.push(item.id);
            });
            // 获取视频id
            upData.videoList = JSON.stringify(videoList);

            if (this.title_active == 1) {
                // 获取标题内容
                const titles = await this.$refs.tag_edit.getTitle();
                // let tag_component = this.$refs.tag_edit;
                // let title = tag_component.textarea;
                if (!titles.length) {
                    this.$message.error("请输入视频标题");
                    return;
                }
                // 串写标题+#+@
                // let tag_arr = tag_component.taps;
                // let friend_arr = tag_component.friends;
                // let tag_ids = [];
                // let friend_ids = [];
                // let tag_str = "";
                // let friend_str = "";
                // if (upData.releaseType != 3) {
                //     // console.log("不是随机发布");
                //     friend_arr.forEach((item) => {
                //         friend_ids.push(item.uid);
                //         friend_str += " @" + item.nickname;
                //     });
                //     upData.mentions = JSON.stringify(friend_ids);
                // }

                // tag_arr.forEach((item) => {
                //     tag_ids.push(item.cid);
                //     tag_str += "#" + item.value + " ";
                // });
                // upData.challenges = JSON.stringify(tag_ids);
                upData.challenges = titles[0].challenges;

                // upData.title = friend_str + title + " " + tag_str;
                upData.title = titles[0].title;
                upData.titles = JSON.stringify(titles);
                console.log(upData);
                // return;
            } else {
                upData.title = "";
                upData.seoId = this.selectKey.id;
            }
            if (upData.releaseType == 2 && !this.send.users.length) {
                this.$message.error("请选择发布用户");
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "发布中....",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let res;
            if (this.upmode == "douyin") {
                res = await publish(upData);
            } else if (this.upmode == "kuaishou") {
                res = await publishKs(upData);
            }
            if (res.code == 200) {
                this.$message.success("发布成功");
                // console.log();
                switch (upData.releaseType) {
                    case 1:
                        console.log(upData.releaseType);
                        break;

                    case 2:
                        this.$alert(
                            "已将发布任务添加到队列中，可前往任务列表查看发布状态",
                            "提示",
                            {
                                confirmButtonText: "前往任务列表",
                                showClose: false,
                                callback: (action) => {
                                    this.$router.push(
                                        `/home/matrix/task?groupId=${res.data.groupId}`
                                    );
                                },
                            }
                        );
                        // this.releaseRecord = res.data.publishResultList;
                        // this.showReleaseRecord = true;
                        break;
                    case 3:
                        // console.log(res.data.groupId);
                        this.openQr(res.data.groupId);
                        break;

                    default:
                        break;
                }
            } else {
                this.$message.error("发布失败");
            }
            loading.close();
        },
        openQr(groupId) {
            this.qr_url = `${this.$store.state.web}/h5/getpublish/?groupId=${groupId}`;
            console.log(this.qr_url);
            this.showQR = true;
        },
        closeQR() {
            this.showQR = false;
        },
        getUser() {
            this.send.users = this.$refs.userGruop.list;
            this.userSelect = false;
        },
        // 子传值父
        getChilder(res) {
            this.show = false;
            this.list = res.list;
        },
        backEdit() {
            this.$emit("back");
        },
        handleNodeClick(e) {
            this.item.push(e);
        },
        async upload(options) {
            const loading = this.$loading({
                lock: true,
                text: "正在更换封面",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let data = {
                coverUrl: options.file,
                id: options.data.id,
            };
            // 获取oss参数
            let sts = await getSignOss({ fileType: "image" });
            sts = sts.data;
            console.log(sts);
            const client = new OSS({
                bucket: sts.bucket,
                accessKeyId: sts.AccessKeyId,
                accessKeySecret: sts.AccessKeySecret,
                stsToken: sts.SecurityToken,
                region: "oss-cn-shanghai",
                refreshSTSToken: async () => {
                    const info = await getSignOss({ fileType: "image" });
                    return {
                        bucket: info.data.bucket,
                        accessKeyId: info.data.AccessKeyId,
                        accessKeySecret: info.data.AccessKeySecretd,
                        stsToken: info.data.SecurityToken,
                    };
                },
                timeout: 300000,
            });

            let file = options.file;
            // console.log(file);
            let fileType = file.type;

            let fileName = file.name;
            let suffix = fileName.split(".")[1];
            let name = Date.now();

            const fileUrl = `${sts.dir}${name}.${suffix}`;
            // console.log(fileUrl);
            let upList = {
                uid: file.uid,
                file: file,
                path: fileUrl,
                p: 0,
            };
            this.uplist.push(upList);

            if (!fileUrl) {
                return;
            }
            try {
                const res = await client.multipartUpload(fileUrl, file, {
                    //防止覆盖
                    headers: { "x-oss-forbid-overwrite": true },
                    // 设置并发上传的分片数量。
                    parallel: 1,
                    mime: fileType,
                    callback: {
                        url: "https://hotkeey.cn/api/app/callBack/doPost",
                        // host: "https://hotkeey.cn",
                        // contentType: "application/x-www-form-urlencoded",
                        contentType: "application/json",
                        body: `bucket=${sts.bucket}&fileUrl=${fileUrl}&fileName=${fileName}&token=${this.token}&directoryId=0&projectType=Material&contentType=`,
                    },
                });
                console.log(res);
                let imgOssUrl = this.$store.state.ossImgUrl;
                if (res.data.status == 200) {
                    let imgUrl = imgOssUrl + res.name;
                    let data = {
                        coverUrl: res.name,
                        id: options.data.id,
                    };
                    let updata = await videoUpdata(data);
                    if (updata.code == 200) {
                        this.videos[options.data.index].coverUrl = imgUrl;
                    } else {
                        this.$message.error("更换封面失败");
                    }
                }
            } catch (error) {
                console.log(error);
            }
            loading.close();
        },
        // 上传限制格式
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                let type = file.type.match(/(\S*)\//)[1];
                if (type != "image") {
                    this.$message.error("请上传jpg,png格式封面");
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        },
        onSuccess(response, file, fileList) {
            // 上传成功回调。
            if (response.code == 200) {
                this.$message.success("上传成功");
                this.videos.unshift(response.data.list[0]);
                let inx = this.upVideo.findIndex((item) => {
                    // console.log(item.uid);
                    return item.uid === file.uid;
                });
                this.upVideo.splice(inx, 1);
            } else {
                this.$message.error("上传失败");
            }
        },
        format(percentage) {
            return percentage == 100 ? "转码中" : `${percentage.toFixed(2)} %`;
        },
    },
};
</script>
<style lang="less" scoped>
// 新
.upload_cover {
    height: 100%;
    width: 100%;
    /deep/ .el-upload,
    /deep/ .el-upload-dragger {
        width: 100%;
    }
}
.title_tab_group {
    display: flex;
    .title_tab_list {
        padding: 10px 0;
        margin-right: 10px;
        cursor: pointer;
        font-size: 14px;
        color: #999;
        &.active {
            position: relative;
            color: #349ffd;
            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 20px;
                height: 3px;
                background-color: #349ffd;
            }
        }
    }
}
.select_user-box {
    height: 420px;
}
.input-w400 {
    width: 400px;
}
.impo-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .source-box {
        flex: 1;
        height: 0;
    }
    .source-btn-box {
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #f3f3f3;
    }
}
.btn-box {
    display: flex;
    justify-content: center;
}
.time-set-box {
    border: 1px solid #e8ecef;
    border-radius: 4px;
    padding: 5px;
    flex: 0 0 160px;
    margin-left: 10px;
}
.release-set-row {
    margin-top: 20px;
}
.set-time {
    display: flex;
    &-item {
        display: flex;
    }
    &-item:first-child {
        flex: 0 0 400px;
    }
    .radio-item {
        padding: 4px 5px;

        /deep/ .el-radio__inner {
            width: 12px;
            height: 12px;
            border-radius: 2px;
        }
        /deep/ .el-radio__label {
            font-size: 12px;
        }
        /deep/ .el-select .el-input.is-focus .el-input__inner {
            border: none;
        }
        /deep/ .el-input__inner {
            border: none !important;
            background-color: #ddd;
        }
    }
}
/deep/ .set-time .el-input__inner {
    border: none !important;
    background-color: #f5f6f8;
}
.title-tis {
    color: #ff6767;
    font-size: 12px;
}

.title-num {
    padding: 0 20px;
    span {
        color: #8597b2;
    }
}
.content {
    background-color: #fff;
    border-radius: 4px;
    padding: 0 15px;
    margin-bottom: 10px;
}
.title {
    cursor: pointer;
    padding: 20px 0;
}
.video-box {
    height: 300px;
    border-radius: 6px;
    border: 1px solid #ced4db;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .video-hd {
        padding: 20px 0;
        flex: 0 0 72px;
        display: flex;
        box-sizing: border-box;
        .up-box {
            display: flex;
            align-items: center;
        }
        .video-num {
            padding-left: 10px;
            span {
                color: #8597b2;
            }
        }
        .up-tis {
            font-size: 12px;
            padding-left: 20px;
            > div:last-child {
                color: #ff6767;
            }
        }
    }
    .video-conten {
        flex: 1;
        padding-bottom: 20px;
        display: flex;
        overflow: auto hidden;
        height: 228px;
        .video-gruop {
            position: relative;
            padding-right: 20px;
            .close-btn {
                position: absolute;
                right: 11px;
                top: -3px;
                font-size: 12px;
                border-radius: 50%;
                border: 1px solid #8597b2;
                display: flex;
                transform: scale(0.6);
                padding: 2px;
                cursor: pointer;
            }
            .video-item {
                display: flex;
                > div {
                    width: 128px;
                    margin-right: 10px;
                    position: relative;
                    a {
                        display: none;
                        position: absolute;
                        z-index: 999;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                > div:hover a {
                    display: block;
                }
                .video-img {
                    height: 178px;
                    background-color: #f5f6f8;
                    border-radius: 8px;
                }
                .video-text {
                    height: 30px;
                    line-height: 30px;
                    font-size: 12px;
                }
            }
        }
    }
}
.tis-box {
    font-size: 12px;
    color: #8597b2;
    margin-top: 10px;
}
.up-card {
    .up-title {
        line-height: 34px;
    }
    .mode-box {
        .mode-item {
            display: flex;
            .mode-select {
                display: flex;
                align-items: center;
                margin-right: 24px !important;
                padding: 20px 14px;
                border-radius: 6px;
                border: 1px solid #ddd;
                width: 178px;
                cursor: pointer;
                &.active {
                    border-color: #349ffd;
                }
                .mode-icon {
                    line-height: 0;
                    img {
                        width: 32px;
                    }
                }
                .mode-tis {
                    font-size: 12px;
                    padding-left: 5px;
                    div:last-child {
                        color: #8597b2;
                    }
                }
            }
        }
    }
}
.up-edit-content {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 6px;
}
.up-edit-title {
    padding-top: 23px;
    .up-edit-header {
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    .up-edit-box {
        margin-top: 10px;
        .up-edit-list {
            display: flex;
            .edit-box {
                flex: 1;
                // height: 100px;
                // padding-bottom: 20px;
            }
            .edit-del {
                flex: 0 0 40px;
                align-items: center;
            }
        }
    }
}
.relation-row {
    margin-top: 20px;
    .up-body {
        margin-top: 10px;
    }
}
.relation-group {
    display: flex;
    .relation-left {
        flex: 0 0 400px;
    }
}
.user-select-box {
    padding: 4px;
    border: 1px solid #e8ecef;
    border-radius: 2px;
    height: 42px;
    box-sizing: border-box;
    overflow-y: auto;
}
.btn-box {
    padding: 10px 0;
}
</style>