<template>
    <div class="textarea-conten h100">
        <div
            class="texarea_list"
            :style="{ width: titles.length > 1 ? '50%' : '100%' }"
            v-for="(item, index) in titles"
            :key="index"
        >
            <div class="tag-textarea h100">
                <div class="textarea-box">
                    <div class="friends-box tap-box">
                        <el-tag
                            :key="i"
                            v-for="(item, i) in friends"
                            closable
                            size="small"
                            :disable-transitions="false"
                            @close="delTag(friends, i)"
                        >
                            @{{ item.nickname }}
                        </el-tag>
                    </div>

                    <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="请输入标题主体"
                        resize="none"
                        v-model="item.title"
                    >
                    </el-input>
                    <div class="taps-box tap-box">
                        <el-tag
                            type="danger"
                            :key="i"
                            v-for="(item, i) in item.tags.txts"
                            closable
                            size="small"
                            :disable-transitions="false"
                            @close="delTag(taps, i)"
                        >
                            #{{ item.value }}
                        </el-tag>
                    </div>
                </div>
                <div class="btn-row" v-if="upmode == 'douyin'">
                    <!-- <el-popover
                        :tabindex="index"
                        :key="index"
                        placement="bottom"
                        v-model="item.friends.show"
                        trigger="click"
                        style="margin-right: 10px"
                        v-if="releaseType != 3"
                    >
                        <div class="">
                            <el-autocomplete
                                size="mini"
                                class="inline-input"
                                v-model="friend"
                                :fetch-suggestions="friendsCheck"
                                placeholder="请输入好友昵称"
                                @select="selectFriends"
                            >
                                <template slot-scope="{ item }">
                                    <div
                                        style="
                                            display: flex;
                                            align-items: center;
                                            font-size: 12px;
                                        "
                                    >
                                        <img
                                            :src="`https://p6.douyinpic.com/aweme/${item.avatar_thumb.uri}`"
                                            width="22px"
                                            height="22px"
                                            style="
                                                border-radius: 50%;
                                                margin-right: 8px;
                                            "
                                            alt=""
                                        />
                                        {{ item.value }}
                                    </div>
                                </template>
                            </el-autocomplete>
                        </div>
                        <el-button
                            slot="reference"
                            type="primary"
                            size="mini"
                            plain
                            >@朋友</el-button
                        >
                    </el-popover> -->
                    <el-popover
                        placement="bottom"
                        v-model="item.tags.show"
                        trigger="click"
                        :ref="`popover${index}`"
                        @show="showTagSelect(index)"
                        @hide="tag = ''"
                    >
                        <div class="">
                            <el-autocomplete
                                size="mini"
                                class="inline-input"
                                v-model="tag"
                                :fetch-suggestions="tagCheck"
                                placeholder="请输入话题"
                                @select="selectTag"
                            >
                            </el-autocomplete>
                        </div>
                        <el-button
                            slot="reference"
                            type="danger"
                            size="mini"
                            plain
                            >#话题</el-button
                        >
                    </el-popover>
                </div>
            </div>
            <div class="edit-del" @click="delTitle(index)">
                <i class="el-icon-delete"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { searchUser, searchTag } from "@/api/publish";
export default {
    name: "TagTextarea",
    props: ["releaseType", "upmode"],
    data() {
        return {
            // 标题内容
            textarea: "",
            // 好友搜索内容
            friend: "",
            // 标签搜索内容
            tag: "",
            // 是否显示好友选择
            showFriend: false,
            // 是否显示话题选择
            showTag: false,
            showTag1: false,
            // 话题选择列表
            taps: [],
            // 好友选择列表
            friends: [],
            titles: [
                {
                    title: "",
                    tags: {
                        ids: [],
                        txts: [],
                        show: false,
                    },
                    friends: {
                        ids: [],
                        txts: [],
                        show: false,
                    },
                },
            ],
            // 操作标题索引
            titleIndex: 0,
        };
    },
    methods: {
        addTitle() {
            const title = {
                title: "",
                tags: {
                    ids: [],
                    txts: [],
                    show: false,
                },
                friends: {
                    ids: [],
                    txts: [],
                    show: false,
                },
            };
            this.titles.push(title);
        },
        delTitle(val) {
            this.titles.splice(val, 1);
        },
        friendsCheck(queryString, cb) {
            let checkUser = { keyword: queryString, pageNum: 1 };
            searchUser(checkUser).then((res) => {
                let tags = res.data.user_list;
                tags.forEach((item, i) => {
                    item.value = item.nickname;
                });
                cb(tags);
            });
        },
        showTagSelect(val) {
            this.titleIndex = val;
        },
        tagCheck(queryString, cb) {
            let checkTap = { keyword: queryString };
            searchTag(checkTap).then((res) => {
                let tags = res.data.sug_list;
                tags.forEach((item, i) => {
                    item.value = item.cha_name;
                });
                cb(tags);
            });
        },
        delTag(list, index) {
            list.splice(index, 1);
        },
        selectTag(item) {
            let titleIndex = this.titleIndex;
            this.titles[titleIndex].tags.show = false;
            this.titles[titleIndex].tags.txts.push(item);
        },
        selectFriends(item) {
            let titleIndex = this.titleIndex;
            this.showFriend[titleIndex].tags.show = false;
            this.showFriend[titleIndex].tags.txts.push(item);
        },
        getTitle() {
            return new Promise((res, req) => {
                const titles = this.titles;
                const data_index = {
                    challenges: [], //话题id
                    title: "", //标题
                };
                const data = [];
                titles.map((item, index) => {
                    let list = {
                        challenges: [], //话题id
                        title: "", //标题
                    };
                    let tagStr = "";
                    let tagIds = [];
                    item.tags.txts.forEach((tag, key) => {
                        tagStr += "#" + tag.value + " ";
                        tagIds.push(tag.cid);
                    });
                    list.challenges = JSON.stringify(tagIds);
                    list.title = tagStr + item.title;
                    if (list.title != "") {
                        data.push(list);
                    }
                });
                // console.log();
                res(data);
            });
        },
    },
};
</script>

<style lang="less" scoped>
span[contenteditable="true"] {
    outline: none;
}

.edit-del {
    font-size: 20px;
    margin-left: 10px;
}
.textarea-conten {
    display: flex;
    flex-wrap: wrap;
    .texarea_list {
        padding: 10px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }
}
.tag-textarea {
    flex: 1;
    position: relative;
    padding-bottom: 40px;
    background-color: #f5f6f8;
    .textarea-box {
        // border: 1px solid #ddd;
        font-size: 12px;
        /deep/ .el-textarea__inner {
            background-color: transparent;
            border: none;
            outline: none;
        }
        .tap-box {
            height: 40px;
            display: flex;
            align-items: center;
            /deep/ .el-tag {
                margin-left: 10px;
            }
        }
    }
    .btn-row {
        position: absolute;
        left: 10px;
        bottom: 10px;
    }
}
.textarea {
    height: 138px;
    padding: 4px 6px;
    box-sizing: border-box;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden auto;
    background-color: #f5f6f8;
    //   -webkit-user-modify: read-write;
    //   -moz-user-modify: read-write;
    //   user-modify: read-write;
    //   -webkit-user-modify: read-write-plaintext-only !important;
    :focus {
        border-color: aqua;
    }
}
.read-text {
    -webkit-user-modify: read-only;
    -moz-user-modify: read-only;
    user-modify: read-only;
    outline: none;
}
.read-write-text {
    -webkit-user-modify: read-write;
    -moz-user-modify: read-write;
    user-modify: read-write;
    outline: none;
}
</style>