<style lang="less">
.el-checkbox__label {
    font-size: 12px;
}
</style>
<template>
    <div class="list-conten item">
        <div class="select-box hd">
            <div class="input-box">
                <el-input
                    v-model="val"
                    size="mini"
                    placeholder="请输入用户昵称"
                    suffix-icon="el-icon-search"
                ></el-input>
            </div>
            <div class="select-box">
                <el-select
                    v-model="select"
                    size="mini"
                    disabled
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>

        <div class="table main">
            <ul class="row table-hd">
                <li>账号({{ total }})</li>
                <li>状态</li>
            </ul>

            <!-- <el-checkbox v-model="check" @change="changeUser">test</el-checkbox> -->
            <div class="list-box">
                <ul class="row" v-for="(item, index) in list" :key="index">
                    <li>
                        <el-checkbox
                            v-model="item.check"
                            @change="changeUser(item)"
                            >{{ item.userNickname }}</el-checkbox
                        >
                    </li>
                    <li>
                        <span class="isAuth" v-if="item.state == 1"
                            >已授权</span
                        >
                        <span v-else>已失效</span>
                    </li>
                </ul>
            </div>
            <el-pagination
                small
                layout="prev, pager, next"
                :total="total"
                @current-change="changeCurr"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getAuthList, groupUser } from "@/api/auth";
import Bus from "@/utils/eventBus";

import qs from "qs";
export default {
    name: "List",
    props: ["mode"],
    data() {
        return {
            val: "",
            select: 1,
            options: [
                {
                    value: 1,
                    label: "全部",
                },
                {
                    value: 2,
                    label: "已授权",
                },
            ],
            list: [],
            // 查询条件
            where: {
                current: 1, //页码
                size: 10, //每页数量
                "orders[0].asc": false,
                "orders[0].column": "create_time",
            },
            // 总记录数
            total: 0,
            selectGroup: {
                groupName: "所有账号",
                id: 0,
            },
        };
    },

    mounted() {},
    created() {
        Bus.$on("group", (res) => {
            console.log(this.mode);
            // this.where.current = 1;
            this.selectGroup = res;
            // console.log(this.selectGroup.id);
            if (this.selectGroup.id) {
                // 有Groupid
                this.where.id = res.id;
                this.getGroupUser();
            } else {
                // 没有Groupid
                delete this.where.id;
                this.getAuthList();
            }
        });
    },
    methods: {
        getAuthList() {
            // console.log(this.$parent.list);
            let where = this.where;
            // where.platformType = this.mode;
            groupUser(where).then((res) => {
                if (res.code == 200) {
                    let data = res.data.records;
                    this.forData(data);
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            });
        },
        getGroupUser() {
            let where = this.where;
            groupUser(where).then((res) => {
                if (res.code == 200) {
                    let data = res.data.records;
                    this.forData(data);
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            });
        },
        forData(arr) {
            let selet_user = this.$parent.list;
            arr.forEach((item) => {
                let check = selet_user.find((list) => list.id === item.id);
                if (check) {
                    item.check = true;
                } else {
                    item.check = false;
                }
            });
        },
        changeCurr(page) {
            this.where.current = page;
            this.getAuthList();
        },
        changeUser(item) {
            this.$emit("change", item);
        },
        checkList() {
            let selet_user = this.$parent.list;
            this.list.forEach((item) => {
                let check = selet_user.find((list) => list.id === item.id);

                if (check) {
                    this.$set(item, "check", true);
                } else {
                    this.$set(item, "check", false);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.list-conten {
    font-size: 12px;
}
.select-box {
    display: flex;
    .select-box {
        width: 100px;
        margin-left: 10px;
    }
}
.table {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    .list-box {
        flex: 1;
        height: 0;
    }
}
.row {
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .isAuth {
        color: #409eff;
    }
}
.table-hd {
    background-color: #f5f6f8;
    border-radius: 3px;
    padding: 0 10px;
}
</style>