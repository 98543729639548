<style lang="less">
.el-icon-caret-right {
    order: 1;
    margin-left: auto;
}
.el-tree-node__content {
    // padding-left: 10px !important;
    margin-bottom: 2px;
}
.el-tree-node__label {
    font-size: 12px;
}
.el-tree-node__expand-icon.is-leaf {
    color: #c0c4cc;
}
</style>
<template>
    <div class="group-box item">
        <div class="select-box">
            <el-input
                v-model="val"
                size="mini"
                placeholder="请输入分组名"
                suffix-icon="el-icon-search"
            ></el-input>
        </div>
        <div class="list main">
            <el-tree
                ref="tree"
                :data="list"
                :props="{ value: 'id', label: 'groupName' }"
                icon-class="el-icon-caret-right"
                empty-text="暂无数据"
                :highlight-current="true"
                :filter-node-method="filterNode"
                @node-click="handleNodeClick"
            >
            </el-tree>
        </div>
    </div>
</template>

<script>
import { getAutoGruopList } from "@/api/auth";

import Bus from "@/utils/eventBus";
export default {
    name: "group",
    props: {
        mode: {
            default: "",
        },
    },
    data() {
        return {
            val: "",
            count: 1,
            // 查询分组条件
            where: {
                size: 200,
                platformType: this.mode,
            },
            // 分组列表
            list: [],
        };
    },

    mounted() {
        this.getGroup();
    },
    created() {},
    watch: {
        val(val) {
            // console.log(val);
            this.$refs.tree.filter(val);
        },
        mode(val) {
            this.where.platformType = val;
            this.getGroup();
        },
    },
    methods: {
        getGroup() {
            console.log("获得分组列表");
            let where = this.where;
            getAutoGruopList(where).then((res) => {
                this.list = res.data.records;
                // let json = {
                //     id: 0,
                //     groupName: "所有账号",
                // };
                // this.list.unshift(json);
            });
        },
        filterNode(val, data) {
            if (!val) return true;
            return data.groupName.indexOf(val) !== -1;
        },
        handleCheckChange(data, checked, indeterminate) {
            // 复选框选择 data:勾选数据,checked：ture勾选 false取消勾选
            // 分组全选操作
            console.log(data, checked, indeterminate);
        },
        handleNodeClick(data) {
            // 点击节点
            // console.log(data);
            Bus.$emit("group", data);
        },
    },
};
</script>

<style lang="less" scoped>
.list {
    padding: 10px 0;
}
</style>