<style lang="less">
.select-conten {
    .item {
        height: 100%;
        display: flex;
        flex-direction: column;
        .main {
            flex: 1;
            overflow: auto;
        }
    }
}
</style>
<style lang="less" scoped>
.select-conten {
    height: 100%;
    display: flex;
    flex-direction: column;
    .select-title {
        flex: 0 0 30px;
        line-height: 30px;
        color: #334681;
        font-weight: bold;
    }
    .select-body {
        flex: 1;
        display: flex;
        height: 0;
        > div {
            padding: 0 5px;
        }
        > div + div {
            border-left: 1px solid #e8ecef;
        }
        .select-group,
        .select-list {
            flex: 0 0 160px;
        }
        .list-box {
            flex: 1;
        }
        // .select-list {
        //     flex: 0 0 100px;
        // }
    }
}
</style>
<template>
    <div class="select-conten">
        <div class="select-title">选择账号</div>
        <div class="select-body">
            <div class="select-group">
                <GROUP :mode="mode" />
            </div>
            <div class="list-box">
                <LIST @change="listChange" :mode="mode" ref="list" />
            </div>
            <div class="select-list">
                <SELECT_LIST :list="list" @delUser="delUser" />
            </div>
        </div>
        <!-- <el-transfer
            v-model="value"
            :data="users"
            :titles="['账号列表', '已选列表']"
            :props="{ key: 'id', label: 'userNickname' }"
            @change="handleChange"
        >
        </el-transfer> -->
    </div>
</template>
<script>
import GROUP from "@/components/auth/group";
import LIST from "@/components/auth/list";
import SELECT_LIST from "@/components/auth/select_list";
import qs from "qs";
export default {
    props: ["mode"],
    name: "SelectUser",
    components: {
        GROUP,
        LIST,
        SELECT_LIST,
    },
    data() {
        return {
            upmode: "douyin",
            list: [],
        };
    },
    watch: {
        mode(val) {
            this.upmode = val;
        },
    },
    mounted() {},
    created() {},
    methods: {
        delUser(index) {
            // console.log(index);
            let item = this.list[index];
            this.list.splice(index, 1);
            this.$refs.list.checkList(item);
        },
        // 接受选中的参数
        listChange(item) {
            if (item.check) {
                // 增加
                this.list.unshift(item);
            } else {
                // 删除
                let del_index = this.list.findIndex(
                    (list) => list.id === item.id
                );
                this.list.splice(del_index, 1);
            }
        },
    },
};
</script>
