<style lang="less" scoped>
.select-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
    .user {
        height: 28px;
        line-height: 28px;
        background-color: #f5f6f8;
        font-size: 12px;
        padding: 0 10px;
        i {
            color: #349ffd;
        }
    }
    .el-icon-delete-solid {
        cursor: pointer;
    }
}
</style>
<template>
    <div class="select-box item">
        <div class="select-title hd">已选账号：{{ list.length }}</div>
        <div class="select-body main">
            <div class="select-row" v-for="(item, index) in list" :key="index">
                <div class="user">
                    <i class="el-icon-user-solid"></i>
                    {{ item.userNickname }}
                </div>
                <i class="el-icon-delete-solid" @click="delUser(index)"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VideoSelectList",
    props: {
        list: {
            type: Array(),
            default: [],
        },
    },
    data() {
        return {
            num: 0,
        };
    },

    mounted() {},

    methods: {
        delUser(index) {
            // console.log(index);
            this.$emit("delUser", index);
        },
    },
};
</script>

<style lang="less" scoped>
.select-title {
    line-height: 28px;
    font-size: 12px;
}
</style>